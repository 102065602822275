import React from "react";
import { Newsletter, Jumbotron } from "../components";
import { Privacy } from "../components/PrivacyNotice";
import Layout from "../containers/Layout";

export default function PrivacyNotice() {
  return (
    <Layout title={"Privacy Notice"}>
      <Jumbotron
        btn="Privacy Notice"
        title="Privacy Notice"
        subTitle="Here is Reeddi's privacy notice"
      />
      <Privacy />
      <Newsletter />
    </Layout>
  );
}
